<!-- eslint-disable prettier/prettier -->
<template>
  <section class="conOfMissionVission h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage
            :headerPage="{ part1: 'SSBD', part2: 'Members' }"
          />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in data"
                      :key="i"
                    >
                      <td>{{ i+1 }}</td>
                      <td>{{ item }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "About Us",
        disabled: true,
      },
      {
        text: "Members",
        disabled: false,
      },
    ],
    pdfsrc: "/pdf/Branches.pdf#toolbar=0&navpanes=1&scrollbar=0",
    data: [
      "فراس عبدالعزيز الفريح",
      "عماد بن عصام غباشى",
      "رحاب يوسف الانصاري",
      "فهد ناصر بهكلي",
      "عثمان هادي ايوب",
      "عامر عليم --",
      "فواز عبدالعزيز القاسم",
      "ابراهيم سامي غيته",
      "خالد احمد خليل",
      "علي محمد عسيري",
      "اشرف خيري زكي رضوان محسن",
      "محمد عبدالمحسن الشعيبى",
      "محمد سمير أمين",
      "سمير محمد العمودي",
      "مامون حسن ابراهيم",
      "حسن بن عبدالله الجاسم",
      "احمد علي بن سلمان",
      "غادة متولي الجوهري",
      "طارق مصطفى عويضه",
      "عبدالرحمن ابراهيم آل العذيبي",
      "رامي عدنان الشريف",
      "حسن سالم آل قريشه",
      "عبدالله محمد الفريد",
      "ايناس محمد الجهني",
      "خالد عبدالله عبد الله",
      "سوزان محمد نقاش محمد",
      "غادة متولى الجوهرى",
      "سعود حسن احمد بليلة",
      "ام سلمه العوض احمد",
      "عثمان عمر رضوى",
      "باسم تحسين حسن البيروتي",
      "علياء علي ابوطالب",
      "هاني حسن علي الهاشمي",
      "محمد حسن المحمدي",
      "عامر -- حنبلي",
      "جمال غانم محمد مصطفى",
      "رانية المنصف كريشان",
      "لينا جعفر الزبير",
      "غالب عبدالواحد محمد اليمني",
      "عبير محمد يوسف",
      "زينب محمد آل جليح",
      "سعد منظور هاشمي",
      "اشرف محمدعتيق وارسى",
      "عهود فواد كشاري",
      "أيمن محسن معشي",
      "عادل عبد الله محمد الجثام",
      "نهله علي مبارك",
      "غادة متولى الجوهرى",
      "عصام عبدالله الطويان",
      "ينيليت ايسكالونا فيفيس",
      "نعيم ارشد شودري شودريNaeem Arshad Chaudhri",
      "وائل محمد القاهري",
      "اشرف محمدعتيق وارسى",
      "عمر عبدالعزيز النجيدى",
      "فهد ابراهيم المحارب",
      "سعود حسن احمد بليلة",
      "علياء علي ابوطالب",
      "أمل محمد البيحاني",
      "عمر بن محمد السحيباني",
      "كفاح بن باقر القظيب",
      "عبدالله بن حسن آل زايد",
      "حسن بن عبدالله الجاسم",
      "محمد سمير أمين",
      "خالد محمد بخيت",
      "اسامه توفيق خوجه",
      "مها مثيب السبيعي",
      "احمد بن طاهر البحراني",
      "نجلاء عبدالرسول فراج",
      "عماد بن عصام غباشى",
      "سامي بن حسين البطاط",
      "منى محمد الفرج",
      "هديل صالح الحداد",
      "ساره صليح العماري",
      "وفاء احمد الجيزاني",
      "محمد خالد النحاس",
      "علي أحمد الشهري",
      "مؤمن فيصل نعساني",
    ],
  }),
  components: {
    HeaderPage,
    Breadcrumb,
  },
};
</script>
<style lang="scss" scoped>
@import "./_founding-regulations.scss";
</style>
